<template>
    <Dialog :title="$t('call.member.title')" :open="open" @cancel="$emit('cancel')" @save="save()" v-if="data" maxWidth="400">
        <v-container fluid class="ma-0 pa-0">
            <v-row>
                <v-col class="d-flex justify-center">
                    <v-avatar size="80">
                        <v-gravatar :email="data.email" protocol="https" default-img="mm" />
                    </v-avatar>
                </v-col>
            </v-row>

            <div class="text-center">
                <p class="mt-3 mb-0"> <v-icon class="mr-1">mdi-account</v-icon> {{data.name}} </p>
                <v-divider />
                <p class="mb-0"> <v-icon size="16">mdi-at</v-icon> {{data.email}} </p>
                <v-divider />
                <p class="mb-0"> <v-icon class="mr-1" size="16">mdi-phone</v-icon> {{ (data.phone) ? data.phone : $t("empty") }} </p>
                <v-divider />
            </div>
        </v-container>
        <v-container fluid class="pa-0">
            <slot name="announce"> 
        </slot>
        </v-container>
    </Dialog>
</template>

<script>
import Dialog from "../components/Dialog.vue";

export default {
    components: {
        Dialog,
    },

    async mounted() {
        
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        entity: {
            type: String,
            default: null
        },

        data: null
    },

    data: () => ({

    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        },
    },

    methods: {
        save(){
            this.$emit('save', this.data)
        }
    }
};
</script>
