<template>
    <Page icon="mdi-video-wireless-outline" :title="form.name" :progress="progress" :error="error" @reload="load()">
        <template v-if="call">
            <ActionBar :updateTime="updateTime">
                <template v-slot>
                    <template v-if="!owner">
                        <v-icon class="mr-1">mdi-account</v-icon> {{call.user.name}}
                    </template>

                    <v-btn 
                        class="ml-4"
                        v-if="owner"
                        color="primary"
                        :loading="saveProgress" 
                        :disabled="saveProgress || dangerProgress"
                        @click="save()"
                    >
                        <v-icon left>mdi-content-save-outline</v-icon> {{$t("save")}}
                    </v-btn>
                </template>
            </ActionBar>

            <Card class="mt-3" v-if="call.can_join">
                <v-container fluid>
                    <v-row class="d-flex justify-center">
                        <v-icon size="60">mdi-video-wireless-outline</v-icon>
                    </v-row>
                    
                    <v-row class="d-flex justify-center mt-5">
                        <span> {{ $t(('call.room.users'))}} 0 </span>
                    </v-row>

                    <v-row class="d-flex justify-center mt-5">
                        <v-btn 
                            color="primary" 
                            :disabled="saveProgress || dangerProgress" 
                            @click="callRoomOpen = true" 
                        >
                            <v-icon left>mdi-call-made</v-icon>Dołącz
                        </v-btn>
                    </v-row>
                </v-container>
            </Card>

            <Card class="mt-3" :title="$t('call.update.title')">
                <v-text-field
                    :label="$t('call.form.name')"
                    v-model="form.name"
                    :error-messages="errors.name"
                    :hide-details="!errors.name"
                    :disabled="saveProgress || dangerProgress"
                    :readonly="!owner"
                    @change="validate('name')"
                    outlined
                    prepend-inner-icon="mdi-video-wireless-outline"
                />

                <v-text-field
                    class="mt-3"
                    :label="$t('call.form.info')"
                    v-model="form.info"
                    :error-messages="errors.info"
                    :hide-details="!errors.info"
                    :disabled="saveProgress || dangerProgress"
                    :readonly="!owner"
                    @change="validate('info')"
                    outlined
                    prepend-inner-icon="mdi-clipboard-text-outline"
                />
                <v-row class="mt-1">
                    <v-col class="pb-0">
                        <v-menu
                            ref="menu1"
                            v-model="calendar"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                            :disabled="!owner"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFormatted"
                                    v-bind="attrs"
                                    v-on="on"
                                    :label="$t('call.create.date')"
                                    :hide-details="!errors.date"
                                    :error-messages="errors.date"
                                    persistent-hint
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    outlined
                                ></v-text-field>
                            </template>

                            <v-date-picker
                                    v-model="form.date"
                                    :locale="this.$i18n.locale"
                                    @input="dateChanged()"
                                    :min="new Date().toISOString()"
                                    no-title
                            />
                        </v-menu>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="form.start_hour"
                                :items="time"
                                :label="$t('call.create.time_start')"
                                menu-props="auto"
                                :hide-details="true"
                                @change="timeChange()"
                                prepend-inner-icon="mdi-clock"
                                outlined
                                :readonly="!owner"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="form.end_hour"
                                :items="endTime"
                                item-text="text"
                                item-value="value"
                                :label="$t('call.create.time_end')"
                                menu-props="auto"
                                :hide-details="true"
                                persistent-hint
                                prepend-inner-icon="mdi-clock"
                                outlined
                                :readonly="!owner"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="col-12 pt-0">
                        <v-textarea
                            class="mt-0"
                            :label="$t('call.form.notes')"
                            v-model="form.notes"
                            rows=3
                            auto-grow
                            :error-messages="errors.notes"
                            :hide-details="!errors.notes"
                            :disabled="saveProgress || dangerProgress"
                            @change="validate('notes')"
                            outlined
                            prepend-inner-icon="mdi-note-outline"
                        />
                    </v-col>
                </v-row>
            </Card>
        </template>

        <Card class="mt-3" :title="$t('call.update.members_title')">
            <template v-slot:headerActions>
                <v-row v-if="owner">
                    <v-col class="d-flex justify-end">
                        <v-btn 
                            :disabled="dangerProgress"
                            text
                            color="success"
                            @click="openSearchUsers = true"
                        > 
                            <v-icon left>mdi-plus-circle-outline</v-icon> {{$t('call.update.user')}}
                        </v-btn>

                         <v-btn 
                            :disabled="call.patient !== null || dangerProgress"
                            text
                            color="success"
                            @click="openSearchPatients = true"
                        > 
                            <v-icon left>mdi-plus-circle-outline</v-icon> {{$t('call.update.patient')}}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <v-simple-table class="mt-3" v-if="call">
                        <thead>
                            <tr>
                                <th class="w-avatar"></th>
                                <th class="primary--text">
                                    <SortHeader 
                                        :text="$t('call.list.user_name')"
                                        v-model="form.sort"
                                        @change="load()"
                                        field="name"
                                        type="alphanum"
                                    />
                                </th>
                                <th class="primary--text">
                                    <SortHeader 
                                        :text="$t('call.list.phone')"
                                        v-model="form.sort"
                                        @change="load()"
                                        field="phone"
                                        type="numeric"
                                    />
                                </th>
                                <th class="primary--text">
                                    <SortHeader 
                                        :text="$t('call.list.type')"
                                        v-model="form.sort"
                                        @change="load()"
                                        field="role.name"
                                        type="alphanum"
                                    />
                                </th>

                                <th class="primary--text w-action"  v-if="owner">
                                    {{ $t("call.list.options") }}
                                </th>
                            
                            </tr>
                        </thead>
                        <tbody style="white-space: nowrap;">

                        <tr v-for="(u, index) in call.users" :key="index">
                            <td class="pt-3 pb-3 w-avatar" >
                                <v-avatar size="40">
                                    <v-gravatar :email="u.email" protocol="https" default-img="mm" />
                                </v-avatar>
                            </td>

                            <td>
                                <tr>
                                    <span class="font-weight-bold">{{ u.title }} {{ u.name }}</span>
                                </tr>
                                <tr>
                                    <span class="grey--text text--darken-2">
                                        <v-icon size="16">mdi-at</v-icon> {{ u.email }}
                                    </span>
                                </tr>
                            </td>

                            <td>
                                <v-icon class="mr-1" size="16">mdi-phone</v-icon> {{ (u.phone) ? u.phone : $t("empty") }}
                            </td>
                            <td>
                                {{ type(u._id, index) }}
                            </td>

                            <td class="w-action"  v-if="owner">
                                <v-btn
                                    :disabled="type(u._id, index)== $t('call.list.owner') || !owner || dangerProgress"
                                    text
                                    color="error"
                                    @click="removeMember(u._id)" 
                                >
                                    <v-icon>mdi-minus-circle-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    
                    <tr v-if="call.patient" > 
                        <td class="pt-3 pb-3 w-avatar" >
                                <v-avatar size="40">
                                    <v-gravatar protocol="https" default-img="mm" />
                                </v-avatar>
                            </td>

                            <td @click="goToPatient(call.patient)">
                                <tr>
                                    <span class="font-weight-bold">{{ call.patient.name }}</span>
                                </tr>
                                <tr>
                                    <span class="grey--text text--darken-2">
                                        <v-icon size="16">mdi-at</v-icon> {{ (call.patient.email) ? call.patient.email : $t("empty") }}
                                    </span>
                                </tr>
                            </td>

                            <td>
                                <v-icon class="mr-1" size="16">mdi-phone</v-icon> {{ (call.patient.phone) ? call.patient.phone : $t("empty") }}
                            </td>
                            <td>
                                {{ $t('call.list.patient') }}
                            </td>

                            <td class="w-action" v-if="owner">
                                <v-btn
                                    text
                                    color="error"
                                    @click="removePatient(call.patient._id)"
                                >
                                    <v-icon>mdi-minus-circle-outline</v-icon>
                                </v-btn>
                            </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </Card>

        <DangerZone
            v-if="call && owner"
            @btnClick="beforeDangerZoneAction()"
            v-model="dialogDangerItem"
            :data="[
                {
                    itemKey: 'changeStatus',
                    itemName: (form.enabled ? $t('call.danger_zone.change_status_to_canceled') : $t('call.danger_zone.change_status_to_active')),
                    itemInfo: $t('call.danger_zone.change_status_info') + ' ',
                    itemInfoGreen: (form.enabled ? $t('active') : null),
                    itemInfoRed: (form.enabled ? null : $t('canceled')),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'changeStatus' ? dangerProgress : false),
                    itemDisabled: !form.enabled && new Date(call.start).getTime() - Date.now() < 60 * 60 * 1000
                },

                {
                    itemKey: 'delete',
                    itemName: $t('call.danger_zone.delete'),
                    itemInfo: $t('call.danger_zone.delete_info'),
                    itemProgress: (dialogDangerItem && dialogDangerItem.itemKey == 'delete' ? dangerProgress : false),
                    itemDisabled: call.patient && Date.now() - new Date(call.end).getTime() < 24 * 60 * 60 * 1000
                }
            ]"
            :disabled="saveProgress || dangerProgress"
        />

        <DangerZoneConfirm
            v-if="dialogDanger"
            :title="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (form.enabled ? $t('call.danger_zone.change_status_to_canceled') : $t('call.danger_zone.change_status_to_active'));
                    break;
                case 'delete':
                    return $t('call.danger_zone.delete');
            }}"
            :description="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return $t('call.danger_zone.dialog.change_status_desc');
                    break;
                case 'delete':
                    return $t('call.danger_zone.dialog.delete_desc');
            }}"
            :descriptionContent="() => {switch (dialogDangerItem.itemKey) {
                case 'changeStatus': 
                    return (form.enabled ? $t('canceled') : $t('active'));
                    break;
                case 'delete':
                    return form.name;
            }}"
            :checkText="() => form.name"
            :dependenciesData="dependenciesData" 
            :open="dialogDanger"
            :save="dialogDangerItem.itemName"
            :saveProgress="dangerDialogSaveProgress"
            @cancel="dialogDanger = false" 
            @save="dangerZoneAction(dialogDangerItem)"
        /> 
            
        <UserCallJoin
            :v-if="userJoin"
            :open="userCallJoin"
            :data="userJoin"
            @cancel="userCallJoin = false; userJoin = null"
            @save="addMember"
        >   
            <template v-slot:announce>
                <div class="d-flex justify-center">
                    <v-checkbox
                        v-model="announceCheckbox"
                        :label="$t('call.member.announce')"
                    ></v-checkbox>
                </div>
            </template>
        </UserCallJoin>

        <CallRoom
            v-if="callRoomOpen"
            :open="callRoomOpen"
            :title="form.name"
            :id="form.id"
            @cancel="callRoomOpen = false"
        />
         
        <Search 
            v-if="openSearchUsers" 
            :open="openSearchUsers" 
            :title="$t('call.update.search_title')" 
            :data="users" 
            :pagination="pagination_users"
            :initLimit="5"
            sort="name " 
            :searchProgress="searchProgress"
            @filter="(form) => onSearchUser(form)" 
            @selectData="onSearchUserBack"
            @cancel="openSearchUsers = false"
        />   

        <Search 
            v-if="openSearchPatients"
            :open="openSearchPatients"
            :searchProgress="searchProgress"
            :title="$t('device.update.patient_select.title')" 
            :data="patients"
            :pagination="pagination_patients"
            :initLimit="5"
            sort="-createdAt "
            @filter="(form) => onSearchPatient(form)" 
            @selectData="onSearchPatientBack" 
            @cancel="openSearchPatients = false"
        />    

        <SaveSnackBar v-model="save_snackbar" :text="$t('save_snackbar')" />   
    </Page>
</template>

<script>
import Page from "./components/Page.vue";
import Card from "./components/Card.vue";
import ActionBar from "./components/ActionBar.vue";
import DangerZone from "./components/DangerZone.vue";
import DangerZoneConfirm from "./dialogs/DangerZoneConfirm.vue";
import CallRoom from "./dialogs/CallRoom.vue";
import SortHeader from "./components/SortHeader.vue";
import Search from "./dialogs/Search.vue";
import UserCallJoin from "./dialogs/UserCallJoin.vue";
import validator from "../plugins/validator";
import * as callValidation from "../api/call/validation";
import SaveSnackBar from "./components/SaveSnackBar.vue";

export default {
    components: {
        Page,
        Card,
        ActionBar,
        CallRoom,
        SortHeader,
        Search,
        UserCallJoin,
        DangerZone,
        DangerZoneConfirm,
        SaveSnackBar
    },
    async mounted() {
        this.form.id = this.$route.params.id;
        await this.load();
    },
    data: () => ({
        form: {
            id: null,
            name: null,
            info: null,
            date: null,
            start_hour: null,
            end_hour: null,
            enabled: null,
            notes: null,
        },

        dateEnd: null,
        calendar: false,
        time: [],
        endTime: [
            {
                value: null,
                text: null
            }
        ],

        errors:{
            name: null,
            info: null,
            start: null,
            end: null,
        },

        error: {
            type: null,
            msg: null,
            code: null
        },

        openSearchUsers: false,
        users: null,
        selectUsers: [],

        pagination_users: {
            total: 0,
            filter: null
        },

        pagination_patients: {
            total: 0,
            filter: null
        },

        userCallJoin: false,
        userJoin: null,
        announceCheckbox: true,
        memberAddLoading: false,

        openSearchPatients: false,
        searchProgress: false,
        patients: null,

        call: null,
        callRoomOpen: false,
        
        updateTime: null,

        progress: true,
        saveProgress: false,
        dangerProgress: false,
        dangerDialogSaveProgress: false,

        dialogDanger: false,
        dialogDangerItem: null,
        dependenciesData: null,
        save_snackbar: false
    }),
    computed: {
        user() {
            return this.$store.state.api.user.data;
        },

        owner(){
            if(!this.call) return false;
            return this.$store.state.api.user.data._id == this.call.user._id;
        },
     
        dateFormatted: {
            get(){
                return new Date(this.form.date).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric"});
            }
        },
    },

    methods: {
        async load() {
            if(this.error.type){
                this.progress = true;
                this.error = {
                    type: null,
                    msg: null,
                    code: null
                }
            }

            const res = await this.$store.dispatch("api/call/get", {id: this.form.id});
            if (res.status == 200) {
                this.call = res.data;
                this.form.name = (this.call.name) ? this.call.name : (this.call.patient) ? this.$t("call.list.patient")+": "+this.call.patient.name : $t("call.no_name");
                this.form.info = this.call.info;
                this.form.date = this.call.start;
                this.form.enabled = this.call.enabled;
                this.form.notes = this.call.notes;
                this.dateEnd = this.call.end;
                this.updateTime = this.call.updateTime ? new Date(this.call.updateTime) : null;

                this.generateStartTime();
                this.generateEndTime();
                
                this.progress = false;
            } else {
                this.error = {
                    type: "network",
                    msg: res.data.error.code,
                    code: res.status
                }
            }
        },

        validate(check) {
            if(check) this.errors[check] = null;
            else Object.assign(this.$data.errors, this.$options.data(this).errors);
            return validator(
                this.form,
                callValidation.update,
                function(key, type, limit) {
                    if(check && key != check) return;
                    let text = this.$t(`form_errors.${key}.${type}`);
                    this.errors[key] = text;
                }.bind(this)
            );
        },

        async save() {
            if(!this.validate()) return;
            this.saveProgress = true;
            await this.$store.dispatch("api/call/update", this.form);
            await this.load();
            this.saveProgress = false;
            this.save_snackbar = true;
        },

        async beforeDangerZoneAction() {
            this.dangerProgress = true;
            await this.load();
            this.dependenciesData = null;
            this.dangerProgress = false;
            this.dialogDanger = true;
        },

        async dangerZoneAction(item) {
            switch (item.itemKey) {
                case "delete":
                    this.dangerDialogSaveProgress = true;
                    this.dependenciesData = null;

                    const res = await this.$store.dispatch("api/call/delete", { id: this.form.id });
                    if (res.status==405) {
                        this.dependenciesData = res.data.data;
                        this.dangerDialogSaveProgress = false;
                        return;
                    } else if (res.status==200) {
                        this.$router.push({ name: 'calls' });
                    } else {
                        this.error = {
                            type: "network",
                            msg: res.data.error.code,
                            code: res.status
                        }
                        this.dialogDanger = false;
                        this.dangerDialogSaveProgress = false;
                    }
                    break;
                case "changeStatus":
                    this.dangerDialogSaveProgress = true;
                    let tempForm = Object.assign({}, this.form);
                    tempForm.enabled = !this.form.enabled;
                    await this.$store.dispatch("api/call/update", tempForm);
                    await this.load();
                    this.dialogDanger = false;
                    this.dangerDialogSaveProgress = false;
                    break;
            }
        },

        type(id, i){
            if(this.call.users[i].id == this.call.user.id) return this.$t('call.list.owner')
            return this.$t('call.list.member');
        },

        dateChanged(){
            this.calendar = false;
        },

        generateStartTime(){
            const today = new Date();

            for(let i=0; i<24; i++) {
                for(let j=0; j<2; j++) {
                    let hour = i;
                    let minute = j === 0 ? 0 : 30*j;
                    let date = new Date();
                    date.setHours(hour, minute, 0, 0);

                    //if(today - date < 0) 
                    this.time.push(i + ":" + (j === 0 ? "00" : 30*j) );
                }
            }

            let d = new Date(this.form.date),
                h = d.getHours(),
                m = 30 * Math.floor(d.getMinutes()/30),
                stamp = h + ":" + (m === 0 ? "00" : m);

            let pos = this.time.indexOf(stamp)
            this.form.start_hour = this.time[pos];
            this.form.end_hour = this.time[pos+2];
        },

        generateEndTime(){
            this.endTime = [];
            this.time.slice(this.time.indexOf(this.form.start_hour), this.time.length).map((time, i) => this.endTime[i] = {value: time});
            const index = this.endTime.length;
            this.time.slice(0, this.time.indexOf(this.form.start_hour)).map((time, i) => this.endTime[i + index] = {value: time});
            for(let i=0; i<this.endTime.length; i++){
                if(i<2){
                    this.endTime[i].text = this.endTime[i].value + " (" + i*30+ " min)";
                }else{
                    this.endTime[i].text = this.endTime[i].value + " (" + 30*i/60 + " godz.)";
                }
            }
            this.endTime[0].disabled = true;
            this.findEndTime();
        },

        findEndTime(){
            let diff = Math.abs(new Date(this.form.date).getTime() - new Date(this.dateEnd).getTime()) / 1800000;
            this.form.end_hour = this.endTime[diff].value;
        },

        timeChange(){
            this.generateEndTime();
            this.form.end_hour = this.endTime[this.endTime.indexOf(this.form.start_hour) +2].value;
        },
        
        async onSearchUser(form) {
            if(this.pagination_users.filter != form.filter) this.form.page = 1;
            this.pagination_users.filter = form.filter;

            this.searchProgress = true;

            form.call = this.form.id;

            const res = await this.$store.dispatch("api/call/users", form);

            if (res.status == 200) {
                this.pagination_users.total = res.pages;
                this.users = [];

                this.memberAddLoading = false;

                //const existId = this.call.users.map(x => x.id);

                res.data.forEach(item => { 
                    //if(existId.indexOf(item._id) == -1 ){
                        this.users.push({
                            element: item,
                            table:{
                                name: {
                                    value: item.name,
                                    icon: "mdi-account"
                                }
                            }
                        });
                    //}
                });     

                this.searchProgress = false;
            }
        },
        
        onSearchUserBack(data){
            this.userJoin = data.element;
            this.userCallJoin = true;
        },

        async onSearchPatient(form) {
            if(this.pagination_patients.filter != form.filter) this.form.page = 1;
            this.pagination_patients.filter = form.filter;

            form.enabled = true;
            form.anonymised = false;

            this.searchProgress = true;
            const res = await this.$store.dispatch("api/patient/all", form);

            if (res.status == 200) {
                this.pagination_patients.total = res.pages;

                this.patients = [];
                
                res.data.forEach(item => { 
                    this.patients.push({
                        element: item,
                        table:{
                            name: {
                                value: item.name,
                                icon: "mdi-account"
                            },
                            birth_date: {
                                value: (new Date(item.birth_date)).toLocaleDateString([], {day: "2-digit", month: "2-digit", year: "numeric",}),
                                icon: "mdi-calendar-range"
                            },
                            national_id: {
                                value: item.national_id ? item.national_id : this.$t("empty"),
                                icon: "mdi-card-account-details-outline"
                            },
                            num_devices: {
                                value: " " + item.num_devices,
                                icon: "mdi-tablet-cellphone"
                            }
                        }
                    });
                });

                this.searchProgress = false;
            }
        },
 
        async onSearchPatientBack(data){
            let res =  await this.$store.dispatch("api/call/addPatient", {
                id: this.form.id,
                patient: data.element._id
            });
            if( res.status == 200 ){
                await this.load();
            }
        },

        async addMember(data){
            this.memberAddLoading = true;
            let res =  await this.$store.dispatch("api/call/members", {
                id: this.form.id,
                member: data._id,
                announce: this.announceCheckbox
            });

            if(res.status == 200) {
                this.userCallJoin = false;
                this.load();
            }
        },

        async removeMember(id){
              let res =  await this.$store.dispatch("api/call/removeMember", {
                id: this.form.id,
                member: id
            });

            if(res.status == 200) {
                this.userCallJoin = false;
                this.load();
            }
        },

        async removePatient(patient){
            let res =  await this.$store.dispatch("api/call/removePatient", {
                id: this.form.id,
                patient: patient
            });
            if( res.status == 200 ){
                await this.load();
            }
        },

        goToPatient(patient){
            if(!this.$store.state.api.user.selectedEntity) 
                this.$router.push({ name: 'patient', params : { id: patient._id, routefrom: 'calls+call.'+this.form.id } }) 
        }
    }
};
</script>