<template>
    <Dialog :fullscreen="true" :removeMargins="true" icon="mdi-video-wireless-outline" :title="title" :open="open">
        <template v-slot:toolbar>
            <v-btn
                dark
                text
                @click="left()"
            >
                {{$t("call.room.leave")}}
            </v-btn>
        </template>
        <div id="call-container"></div>
    </Dialog>
</template>

<style scoped>
    #call-container{
        width: 100%;
        height: calc(100vh - 64px);
    }
</style>

<script>
import Dialog from "../components/Dialog.vue";

export default {
    components: {
        Dialog
    },

    props: {
        open: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: null
        },

        id: {
            type: String,
            default: null
        }
    },

    mounted() {
        this.load();
    },

    beforeDestroy(){

    },

    data: () => ({
        call: null,
        joined: false,
        loaded: false,
        api: null,
        
        update_time: null,
        progress: true,
    }),

    computed: {
        user() {
            return this.$store.state.api.user.data;
        },

        owner(){
            return this.$store.state.api.user.data._id == this.call.user._id;
        }
    },

    methods: {
        async load() {
            const res = await this.$store.dispatch("api/call/join", {id: this.id});

            if (res.status == 200) {
                this.call = res.data.call;
                this.call.update_time = this.call.update_time ? new Date(this.call.update_time) : null;
                this.progress = false;

                this.$nextTick(() => {
                    this.join(res.data.token);
                });
            }
        },

        join(token){
            this.api = new JitsiMeetExternalAPI("8x8.vc", {
                parentNode: document.querySelector('#call-container'),

                roomName: this.call.room.id,
                jwt: token,

                configOverwrite:{
                    subject: this.title,
                    hideConferenceSubject: true,
                    defaultLanguage: this.$i18n.locale,
                    prejoinPageEnabled: true,
                    disableProfile: true,
                    doNotStoreRoom: true,
                    readOnlyName: true,
                    enableWelcomePage: false,

                    toolbarButtons: [
                       'camera',
                       'chat',
                       'closedcaptions',
                       'desktop',
                       'download',
                       'embedmeeting',
                       'etherpad',
                       'filmstrip',
                       'fullscreen',
                       'hangup',
                       'help',
                       'livestreaming',
                       'microphone',
                       'mute-everyone',
                       'mute-video-everyone',
                       'participants-pane',
                       'profile',
                       'raisehand',
                       'recording',
                       'select-background',
                       'settings',
                       'shareaudio',
                       'sharedvideo',
                       'shortcuts',
                       'stats',
                       'tileview',
                       'toggle-camera',
                       'videoquality',
                       '__end'
                    ],
                },
            });

            this.api.addListener("readyToClose", this.left);
        },

        left(){
            if(this.api) this.api.dispose();
            this.api = null;
            this.$emit("cancel");
        }
    }
};
</script>